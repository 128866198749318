import React from 'react'
import { Link } from 'react-router-dom'
import i18n from 'i18next'

import AccountActions from '../../Redux/Account/actions'
import { useDispatch, useSelector } from 'react-redux'

import clsx from 'clsx'

const NavigationTopBar = ({ selected = 0 }) => {

  const dispatch = useDispatch()
  const profile = useSelector( state => state.auth.user )

  return (
    <nav className="navbar is-dark" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link
          to='/home'
          className={clsx('navbar-item', selected === 0 ? 'is-active' : null) }>
          Home
        </Link>
        <Link
          to='/categories'
          className={clsx('navbar-item', selected === 1 ? 'is-active' : null) }>
          Categorías
        </Link>
        <Link
          to='/work-services'
          className={clsx('navbar-item', selected === 2 ? 'is-active' : null) }>
          Servicios
        </Link>
        <Link
          to='/worker/requests'
          className={clsx('navbar-item', selected === 3 ? 'is-active' : null) }>
          Solicitudes
        </Link>
      </div>
      <div className="navbar-end">
        <div className="navbar-item has-dropdown is-hoverable">

          <Link
            to='/account'
            className='navbar-link'>{ i18n.t('myAccount') }</Link>

          <div className='navbar-dropdown is-right'>
            <div className='navbar-item'>
              { profile && (
                profile.email
              )}
            </div>
            <div className='navbar-item is-pulled-right'>
              <Link
                to='/account?edit'
                className='button is-link'>{ i18n.t('editProfile') }</Link>
            </div>
            <div className="is-clearfix" />
            <hr className='navbar-divider'/>
            <a
              onClick={ () => dispatch( AccountActions.logOut() ) }
              className='navbar-item'>
              { i18n.t('signOut') }
            </a>
          </div>
        </div>

      </div>
    </nav>
  )
}

export default NavigationTopBar
