import React, { useState, useEffect } from 'react'

import { Formik, Form, Field, ErrorMessage } from 'formik'

import * as Yup from 'yup'
import _ from 'lodash'

import styles from './styles/form.module.sass'

const CategorySchema = Yup.object().shape({
  name: Yup.string()
    .required()
})


const CategoryForm = ({ values, onSubmit }) => {

  return (
    <Formik
      initialValues={values}
      validationSchema={CategorySchema}
      onSubmit={ values => onSubmit(values) }>
      { ({ values, errors, touched, isSubmitting }) => (
        <Form>
          <div>
            <div className={ styles["container-input"] }>
              <label className={styles['input-label']} htmlFor="name">Nombre</label>
              <Field
                className={styles.input}
                name="name" />
              <ErrorMessage name="name" component="div" />
            </div>
          </div>
          <section className="section">
            <div className="container">
              <div className="level is-mobile">
                <div className="level-item level-right">
                  <button
                    className="button is-success"
                    type="submit">
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          </section>


        </Form>
      )}

    </Formik>
  )
}

export default CategoryForm
