import { combineReducers } from 'redux'

const rootReducer = combineReducers({
  account: require('./Account/reducer').reducer,
  auth: require('./Auth/reducer').reducer,
  workServices: require('./WorkServices/reducer').reducer,
  worker: require('./Worker/reducer').reducer,
  categories: require('./Categories/reducer').reducer,
})

export default rootReducer
