import React, { useState } from 'react'

import {
  MdModeEdit,
  MdDelete,
  MdCheck,
  MdClose,
  MdVisibilityOff,
  MdVisibility,
} from 'react-icons/md'

import clsx from 'clsx'

import styles from './item_styles.module.sass'

const ItemControls = ({ loading, inactive, onEdit, onDelete, onChangeActive }) => {

  const [ showConfirmDelete, setShowConfirmDelete ] = useState(false)

  return (
    <div>
      { showConfirmDelete ? (
        <div>
          Are you sure to delete?
          <nav className={ clsx("level", "is-mobile", styles.icons) }>
            <div className="level-left">
              <a
              className="level-item"
              onClick={ () => {
                setShowConfirmDelete(false)
                onDelete()
              }}>
                <MdCheck />
              </a>
              <a
                className="level-item"
                onClick={ () => setShowConfirmDelete(false) }>
                <MdClose />
              </a>
            </div>
          </nav>
        </div>
      ): (
        <nav className={ clsx("level", "is-mobile", styles.icons) }>
          <div className="level-left">
            <a className="level-item"
              onClick={ () => onEdit() }>
              <MdModeEdit />
            </a>
            <a
              className="level-item"
              onClick={ () => setShowConfirmDelete(true) }>
              <MdDelete />
            </a>
            <a
              className="level-item"
              onClick={ () => onChangeActive() }>
              { inactive ? <MdVisibilityOff /> : <MdVisibility /> }
            </a>
            { loading && (
              <img
                style={{ width: 25, height: 25 }}
                src={require("../../Assets/img/gear-gray.gif") }
                alt="" />
            )}
          </div>
        </nav>

      )}

    </div>

  )
}

export default ItemControls
