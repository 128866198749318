import React from 'react'
import { useField } from 'formik'

import _ from 'lodash'

import Select from 'react-select'


export default ({ field, form, options }) => {
  return (
    <Select
      name={field.name}
      onBlur={field.onBlur}
      value={options ? options.find(option => _.isEqual(option.value, field.value) ) : ''}
      onChange={({ value }) => form.setFieldValue(field.name, value)}
      options={options}
    />
  );
}
