import React, { useState, useEffect } from 'react'

// import WorkServiceItem from './WorkServiceItem'

import ItemControls from './ItemControls'

import {
  MdAdd,
  MdSort,
  MdSave,
  MdDone,
} from 'react-icons/md'

import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import styles from './styles.module.sass'
import itemStyles from './item_styles.module.sass'

import clsx from 'clsx'

const Modal = ({ name, renderForm, editable, onSubmit, onClose }) => {
  return (
    <div className={ clsx("modal", "is-active") }>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">
            { editable ? "Actualizando" : "Nuevo" } { name }
          </p>
          <button
            className="delete"
            aria-label="close"
            onClick={ () => onClose() }>
          </button>
        </header>
        <section className="modal-card-body">
          { renderForm() }
        </section>
      </div>

    </div>
  )
}

const Item = SortableElement(({value, renderItem, index, loading, onEdit, onDelete, onChangeActive }) => (
  <div className={ clsx("box", value.status === "inactive" ? itemStyles.inactive : null ) }>
    <div className="content">
      <div className={clsx(itemStyles.container_item, "columns", "is-mobile")}>
        { renderItem(value) }
        <ItemControls
          loading={loading}
          inactive={value.status === "inactive"}
          onChangeActive={() => onChangeActive(!(value.status !== "inactive"), value)}
          onEdit={() => onEdit(value)}
          onDelete={ () => onDelete(value) } />
      </div>
    </div>
  </div>
))

const List = SortableContainer( ({ items, renderItem, onEdit, disabled, loading, onDelete, onChangeActive  }) => {
  return (
    <div>
      { items.map( (item, index) =>
        <Item
          renderItem={renderItem}
          loading={ loading === item._id }
          disabled={disabled || item.status === "inactive" }
          key={`ws-${index}`}
          value={item}
          index={index}
          onEdit={() => onEdit(item) }
          onDelete={onDelete}
          onChangeActive={onChangeActive} />
      ) }
    </div>
  )
})

const Crud = ({ items = [], renderItem, renderForm, name,
                loading, onDelete, onChangeActive, onChangeCurrentItem,
                onChangeOrder, onSaveOrder, saved, saving, sortable }) => {

  const [sorting, setSorting] = useState(false)
  const [innerSaved, setInnerSaved] = useState(false)
  const [showSubmitModal, setShowSubmitModal] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)


  useEffect( () => {
    if (saved) {
      setInnerSaved(true)
      setShowSubmitModal(false)
      setSorting(false)
      setTimeout( () => {
        setInnerSaved(false)
      }, 3000)
    }
  }, [saved])

  return (
    <div>
      <div className={ styles.container_buttons }>
        { innerSaved && (
          <div
            className={ styles.container_saved }>
            <MdDone className={"has-text-success"} size={25} />
          </div>
        )}
        { saving && (
          <div
            className={ styles.container_saving }>
            <img width="30" height="30" src={require("../../Assets/img/gear.gif")} alt="loading" />
          </div>
        )}
        { sorting && (
          <div
            className={ styles.container_save }
            onClick={ () => onSaveOrder() }>
            <MdSave className={"has-text-info"} size={25} />
          </div>
        )}
        <div
          className={ styles.container_add }
          onClick={ () => {
            setCurrentItem(null)
            onChangeCurrentItem(null)
            setShowSubmitModal(true)
          }}>
          <MdAdd className="has-text-grey" size={30} />
        </div>
        { sortable && (
          <div
            className={ styles.container_sort }
            onClick={ () => setSorting(!sorting) }>
            <MdSort className={ sorting ? "has-text-info" : "has-text-grey" } size={30} />
          </div>
        )}
      </div>
      <List
        onSortEnd={ ({ oldIndex, newIndex}) => onChangeOrder(oldIndex, newIndex) }
        items={items}
        renderItem={renderItem}
        loading={loading}
        disabled={!sorting}
        onDelete={onDelete}
        onEdit={ item => {
          onChangeCurrentItem(item)
          setShowSubmitModal(true)
        }}
        onChangeActive={onChangeActive} />

      { showSubmitModal && (
        <Modal
          name={name}
          onClose={ () => setShowSubmitModal(false) }
          renderForm={renderForm}
        />
      )}
    </div>
  )
}

export default Crud
