import Immutable from 'seamless-immutable'

export default Immutable({
  loading: false,
  errorLoading: null,
  all: [],
  active: [],
  loaded: false,

  updatingCategory: false,
  updatingCategoryError: null,
  updatedCategory: false,

  savingSortCategories: false,
  savingSortCategoriesError: null,
  savedSortCategories: false,
})
