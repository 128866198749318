import InitialState from './'
import WorkerActions from './actions'

const checkStatus = worker => {
  switch (worker.status) {
    case 'pending':
    case 'incomplete':
      return 'En proceso'
    case 'completed':
      return 'Por aprobar'
    case 'rejected':
      return 'No aprobado'
    case 'active':
      return 'Aprobado'
    default: return ''
  }
}

export const reducer = ( state = InitialState, action) => {
  switch (action.type) {
    case WorkerActions.loadEpsRequest().type:
      return state.merge({
        epsLoading: true,
        epsError: null
      })
    case WorkerActions.loadEpsSuccess().type:
      return state.merge({
        epsLoading: false,
        epsError: null,
        eps: action.payload.options
      })
    case WorkerActions.loadEpsFailed().type:
      return state.merge({
        epsLoading: false,
        epsError: action.payload.error
      })


    case WorkerActions.loadWorkersRequest().type:
      return state.merge({
        workersLoading: true,
        workersLoadingError: null
      })
    case WorkerActions.loadWorkersSuccess().type:
      return state.merge({
        workersLoading: false,
        workersLoadingError: null,
        workersPagination: action.payload.pagination,
        workers: action.payload.workers.map( worker => ({ ...worker, statusLabel: checkStatus(worker) }) )
      })
    case WorkerActions.loadWorkersFailed().type:
      return state.merge({
        workersLoading: false,
        workersLoadingError: action.payload.error,
      })

    case WorkerActions.loadWorkerRequest().type:
      return state.merge({
        workerLoading: true,
        workerLoadingError: null
      })
    case WorkerActions.loadWorkerSuccess().type:
      return state.merge({
        workerLoading: false,
        workerLoadingError: null,
        worker: action.payload.worker,
      })
    case WorkerActions.loadWorkerFailed().type:
      return state.merge({
        workerLoading: false,
        workerLoadingError: action.payload.error,
      })

    case WorkerActions.changeWorkerStatusRequest().type:
      return state.merge({
        workerLoading: true,
        workerLoadingError: null,
        workerUpdated: false,
      })
    case WorkerActions.changeWorkerStatusSuccess().type:
      return state.merge({
        workerLoading: false,
        workerLoadingError: null,
        workerUpdated: true,
        worker: action.payload.worker,
      })
    case WorkerActions.changeWorkerStatusFailed().type:
      return state.merge({
        workerLoading: false,
        workerLoadingError: action.payload.error,
        workerUpdated: false,
      })
    case WorkerActions.resetWorker().type:
      return state.merge({
        workerLoading: false,
        workerLoadingError: null,
        workerUpdated: false,
      })
    default:
      return state
  }
}
