import React from 'react'
import { useField, splitFormProps } from 'react-form'
import clsx from 'clsx'

function MultiSelectField(props) {
  const [field, fieldOptions, { options, ...rest }] = splitFormProps(props);

  const {
    value = [],
    setValue,
    meta: { isTouched, error }
  } = useField(field, fieldOptions);

  const handleSelectChange = e => {
    const selected = Array.from(e.target.options)
      .filter(option => option.selected)
      .map(option => option.value);

    setValue(selected);
  };

  return (
    <div className={ clsx("select", isTouched && error ? "is-danger" : null ) }>
      <select {...rest} value={value} onChange={handleSelectChange}  multiple>
        <option disabled value="" />
        {options.map(option => {
          if (option.label && option.id) {
            return (
              <option key={option.id} value={option.id}>
                {option.label}
              </option>
            )
          }else {
            return (
              <option key={option} value={option}>
                {option}
              </option>
            )
          }
        })}
      </select>
    </div>
  );
}

export default MultiSelectField
