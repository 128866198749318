import React, { useState, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import AccountActions from '../../Redux/Account/actions'
import WorkerActions from '../../Redux/Worker/actions'

import Loader from '../../Components/Loader'
import BasicsForm from './Forms/Basics'
import ServicesForm from './Forms/Services'
import WorkReferencesForm from './Forms/WorkReferences'

import WorkServicesActions from '../../Redux/WorkServices/actions'

const WorkerSignup = ({ history, location }) => {

  const dispatch = useDispatch()
  const {
    workerRegistrationStatusLoading,
    workerRegistrationStatusError,
    workerRegistrationData,

    workerUpdateLoading,
    workerUpdateError,
    workerUpdateCalled,
  } = useSelector( state => state.account )

  const eps = useSelector( state => state.worker.eps )
  const data = useSelector( state => state.account.workerRegistrationData )

  const workServices = useSelector( state => state.workServices.data )

  const [currentStep, setCurrentStep] = useState(0)

  useEffect( () => {
    const token = new URLSearchParams(location.search).get("token")
    dispatch( AccountActions.workerRegistrationStatus(token) )
  }, [dispatch, location])

  useEffect( () => {
      if (workerRegistrationData) {
        dispatch( WorkerActions.getEps() )
      }
  }, [dispatch, workerRegistrationData])

  useEffect( () => {
    dispatch( WorkServicesActions.getWorkServices({ status: 'active' }) )
  }, [])

  useEffect( () => {

    if (currentStep === 2 && workerUpdateCalled && workerRegistrationData.worker?.status === 'completed') {
      history.replace('/worker/thanks')
    }

  }, [currentStep, workerUpdateCalled])

  return (
    <div className="container">
      <section className="hero">
        <div className="hero-body">
          <div className="container">
            <div style={{ textAlign: 'center', marginBottom: 40 }}>
              <img
                style={{ width: 80, height: 80 }}
                src={ require('../../Assets/img/logo.png') } alt="logo" />
            </div>
            <h1 className="title">
              Registro de trabajador
            </h1>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <h2 className="subtitle">
            Al finalizar este registro, tus datos serán validados y podrás ofrecer tus servicios a miles de personas.
          </h2>
        </div>
      </section>
      <section className="section">
        <div className="container">
        { workerRegistrationStatusLoading && (
          <div>
            <Loader />
          </div>
        )}
        { workerRegistrationStatusError ? (
          <div>
            Lo sentimos, { workerRegistrationStatusError.message }
          </div>
        ) : (workerRegistrationData && (
          <div>
          { currentStep ===  0 && (
            <div>
              <h2 className="subtitle">Servicios a ofrecer</h2>
              <ServicesForm
                defaultValues={data}
                options={workServices?.map( service => ({ id: service._id, label: service.name })) || []}
                onSubmit={ values => {
                  window.scrollTo(0, 0)
                  dispatch( AccountActions.workerUpdate({ worker: { work_services: values.work_services } }))
                  setCurrentStep(1)
                }} />
            </div>
          )}
          { currentStep === 1 && (
            <div>
              <h2 className="subtitle">Datos Básicos</h2>
              <BasicsForm
                defaultValues={{
                  ...data,
                  eps: data.worker?.eps,
                  arl: data.worker?.arl,
                }}
                epsOptions={eps}
                onSubmit={ values => {
                  window.scrollTo(0, 0)
                  const worker = { eps: values.eps, arl: values.arl }
                  let basics = values
                  delete basics.eps
                  delete basics.arl
                  dispatch( AccountActions.workerUpdate({ basics, worker }))
                  setCurrentStep(2)
                }} />
            </div>
          )}

          { currentStep === 2 && (
            <div>
              <h2 className="subtitle">Referencias Laborales</h2>
              <WorkReferencesForm
                defaultValues={{
                  fullnameFirst: data.worker?.references?.[0]?.fullname,
                  phoneFirst: data.worker?.references?.[0]?.phone,

                  fullnameSecond: data.worker?.references?.[1]?.fullname,
                  phoneSecond: data.worker?.references?.[1]?.phone,

                  fullnameThird: data.worker?.references?.[2]?.fullname,
                  phoneThird: data.worker?.references?.[2]?.phone,
                }}
                onSubmit={ values => {

                  const references = [
                    {
                      fullname: values.fullnameFirst,
                      phone: values.phoneFirst,
                    },
                    {
                      fullname: values.fullnameSecond,
                      phone: values.phoneSecond,
                    },
                    {
                      fullname: values.fullnameThird,
                      phone: values.phoneThird,
                    }
                  ]

                  dispatch( AccountActions.workerUpdate({ worker: { references } }) )
                }}
                onCancel={ () => {
                  setCurrentStep(1)
                }} />
            </div>
          )}

          </div>
        ))}
        </div>
      </section>
    </div>
  )
}

export default WorkerSignup
