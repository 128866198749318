import React from 'react'

import i18n from 'i18next'

import {
  isValidEmail,
 } from '../../Services/Validations'

import AccountActions from '../../Redux/Account/actions'
import Loader from '../../Components/Loader'

import { useDispatch, useSelector } from 'react-redux'

const ForgotPasswordScreen = ({ history }) => {

  const [ email, setEmail ] = React.useState('')
  const [ invalidEmail, setInvalidEmail ] = React.useState('')

  const dispatch = useDispatch()

  const {
    forgotPasswordLoading,
    forgotPasswordError,
    forgotPasswordSent } = useSelector( state => state.account )

  function checkEmail(email) {
    setEmail( email )
    setInvalidEmail( isValidEmail(email) )
  }

  function sendForgot() {
    if (invalidEmail) return
    dispatch( AccountActions.forgotPassword(email) )
  }

  return (
    <div>
      <div>
        <br/>
        <div className="columns is-centered">
          <div className="column is-4">

            <div className="title">
              { i18n.t("recoverPassword") }
            </div>

            <div className="field">
              <label className="label">{ i18n.t("email") }</label>
              <div className="control">
                <input
                  className="input"
                  onChange={ event => checkEmail(event.target.value)  }
                  type="email"
                  placeholder={ i18n.t("email") } />
              </div>

              { invalidEmail && email.length > 4 &&
                <span className="has-text-danger">{ i18n.t("invalidEmail") }</span> }
            </div>

            <div className="field">
              <div className="control">
                <button
                  onClick={ () => sendForgot() }
                  className="button is-link">
                  { i18n.t("send") }
                  </button>
              </div>
            </div>

            { forgotPasswordLoading && (
              <Loader />
            )}

          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordScreen
