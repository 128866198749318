import React, { useState, useEffect } from 'react'

import { Formik, Form, Field, ErrorMessage } from 'formik'

import Select from '../../Components/Form/Select'

import * as Yup from 'yup'
import _ from 'lodash'

import styles from './Styles/form.module.sass'

const WorkServiceSchema = Yup.object().shape({
  name: Yup.string()
    .required(),
  color: Yup.string()
    .required(),
  description: Yup.string()
    .required()
})

const RateInput = ({ label, price, onChangePrice, defaultEnabled }) => {

  const [enabled, setEnabled] = useState(defaultEnabled)

  useEffect( () => {
    if (!enabled) {
      onChangePrice(null)
    }
  }, [enabled])

  return (
    <div className={styles['rate-option']}>
      <div className={styles['rate-name']}>{ label }</div>
      <div className={styles['rate-available']}>
        <div>
          <label className="checkbox">
            Si
            <input
              type="checkbox"
              checked={enabled}
              onChange={ () => setEnabled(!enabled) } />
          </label>
        </div>
        <div style={{ marginLeft: 20 }}>
          <label className="checkbox">
            No
            <input
              type="checkbox"
              checked={!enabled}
              onChange={ () => setEnabled(!enabled) } />
          </label>
        </div>
      </div>
      { enabled && (
        <div>
          <label className="checkbox">
            <span className="field">
              <p className="control has-icons-left has-icons-right">
                <input
                  type="text"
                  value={price}
                  onChange={ event => onChangePrice(event.target.value) } />
                  <span className="icon is-left">
                    $
                  </span>
              </p>
            </span>
          </label>
        </div>
      ) }
    </div>
  )
}

const WorkServiceForm = ({ values, availableCategories, onSubmit }) => {

  return (
    <Formik
      initialValues={values}
      validationSchema={WorkServiceSchema}
      onSubmit={ values => onSubmit(values) }>
      { ({ values, errors, touched, isSubmitting }) => (
        <Form>
          <div>
            <div className={ styles["container-input"] }>
              <label className={styles['input-label']} htmlFor="name">Nombre</label>
              <Field
                className={styles.input}
                name="name" />
              <div className={styles["input-error"]}>
                <ErrorMessage
                  name="name"
                  component="div" />
              </div>
            </div>
            <div className={ styles["container-input"] }>
              <label className={styles['input-label']} htmlFor="color">Color</label>
              <Field
                className={styles.input}
                name="color" />
              <div className={styles["input-error"]}>
                <ErrorMessage
                  name="color"
                  component="div" />
              </div>
            </div>
            <div className={ styles["container-input"] }>
              <label className={styles['input-label']} htmlFor="description">Descripción</label>
              <Field
                as="textarea"
                className={styles["input-textarea"]}
                name="description" />
              <div className={styles["input-error"]}>
                <ErrorMessage
                  name="description"
                  component="div" />
              </div>
            </div>
            <div className={ styles["container-input"] }>
              <label className={styles['input-label']} htmlFor="category">Categoría</label>
              <Field
                name="category"
                component={Select}
                options={availableCategories.map( category => ({
                  value: category,
                  label: category.name,
                }))} />
            </div>
            <div className={ styles["container-input"] }>
              <label className={styles['input-label']} htmlFor="icon_url">Icono</label>
              <Field
                className={styles.input}
                name="icon_url" />
              <div className={styles["input-error"]}>
                <ErrorMessage
                  name="icon_url"
                  component="div" />
              </div>
              { touched.icon_url || values.icon_url && (
                <div className={styles["image-preview"]}>
                  <img src={ values.icon_url } />
                </div>
              )}
            </div>

          </div>
          <div>
            <div className={styles['rate-title']}> Modo de trabajo (Tarifario) </div>

            <Field name="rate.hours">
              {({ field: { value }, form: { setFieldValue } }) => (
                <RateInput
                  label="Por horas"
                  price={value}
                  defaultEnabled={ value !== 0 && value !== null && value !== undefined }
                  onChangePrice={ price => setFieldValue('rate.hours', Number(price)) } />
              )}
            </Field>

            <Field name="rate.midtime">
              {({ field: { value }, form: { setFieldValue } }) => (
                <RateInput
                  label="Medio Tiempo"
                  price={value}
                  defaultEnabled={ value !== 0 && value !== null && value !== undefined }
                  onChangePrice={ price => setFieldValue('rate.midtime', Number(price)) } />
              )}
            </Field>

            <Field name="rate.fulltime">
              {({ field: { value }, form: { setFieldValue } }) => (
                <RateInput
                  label="Tiempo Completo"
                  price={value}
                  defaultEnabled={ value !== 0 && value !== null && value !== undefined }
                  onChangePrice={ price => setFieldValue('rate.fulltime', Number(price)) } />
              )}
            </Field>

            <Field name="rate.consultation">
              {({ field: { value }, form: { setFieldValue } }) => (
                <RateInput
                  label="Consulta"
                  price={value}
                  defaultEnabled={ value !== 0 && value !== null && value !== undefined }
                  onChangePrice={ price => setFieldValue('rate.consultation', Number(price)) } />
              )}
            </Field>
          </div>
          <section className="section">
            <div className="container">
              <div className="level is-mobile">
                <div className="level-item level-right">
                  <button
                    className="button is-success"
                    type="submit">
                    Guardar
                  </button>

                </div>
              </div>
            </div>
          </section>


        </Form>
      )}

    </Formik>
  )
}

export default WorkServiceForm
