import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import es from './Resources/es.json'
import en from './Resources/en.json'

export default function () {
  i18next
    .use(LanguageDetector)
    .init({
      debug: true,
      resources: {
        en: {
          translation: en
        },
        es: {
          translation: es
        }
      }
    })
}
