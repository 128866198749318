import CategoriesActions from './actions'
import InitialState from './'

import arrayMove from 'array-move'

export const reducer = (state = InitialState, action) => {
  switch (action.type) {
    case CategoriesActions.fetchCategoriesRequest().type:
      return state.merge({
        loading: true,
        errorLoading: null,
        loaded: false,
      })
    case CategoriesActions.fetchCategoriesSuccess().type:
      return state.merge({
        loading: false,
        errorLoading: null,
        all: action.payload.categories,
        active: action.payload.categories.filter( category => category.status === 'active' ),
        loaded: true,
      })
    case CategoriesActions.fetchCategoriesFailed().type:
      return state.merge({
        loading: true,
        errorLoading: action.payload.error,
        loaded: false,
      })

    case CategoriesActions.postCategoryRequest().type:
      return state.merge({
        loading: true,
        errorLoading: null,
        loaded: false,
      })
    case CategoriesActions.postCategorySuccess().type:
      const allPost = [...state.all, action.payload.category]
      return state.merge({
        loading: false,
        errorLoading: null,
        all: allPost,
        active: allPost.filter( category => category.status === 'active' ),
        loaded: true,
      })
    case CategoriesActions.postCategoryFailed().type:
      return state.merge({
        loading: true,
        errorLoading: action.payload.error,
        loaded: false,
      })

    case CategoriesActions.deleteCategoryRequest().type:
      return state.merge({
        loading: true,
        errorLoading: null,
        loaded: false,
      })
    case CategoriesActions.deleteCategorySuccess().type:

      const allDelete = state.all.filter( category => category._id !== action.payload.category._id )

      return state.merge({
        loading: false,
        errorLoading: null,
        all: allDelete,
        active: allDelete.filter( category => category.status === 'active' ),
        loaded: true,
      })
    case CategoriesActions.deleteCategoryFailed().type:
      return state.merge({
        loading: true,
        errorLoading: action.payload.error,
        loaded: false,
      })

    case CategoriesActions.putCategoryRequest().type:
      return state.merge({
        updatingCategory: true,
        updatingCategoryError: null,
        updatedCategory: false,
      })
    case CategoriesActions.putCategorySuccess().type:

      const data = state.all
                        .asMutable()
                        .map( category => category._id === action.payload.category._id ? action.payload.category : category )

      return state.merge({
        updatingCategory: false,
        updatingCategoryError: null,
        updatedCategory: true,
        all: data,
        active: data.filter( category => category.status === 'active' ),
      })
    case CategoriesActions.putCategoryFailed().type:
      return state.merge({
        updatingCategory: false,
        updatingCategoryError: action.payload.error,
        updatedCategory: false,
      })

    case CategoriesActions.changeOrderCategories().type:
      return state.merge({
        all: arrayMove(state.all.asMutable(), action.payload.oldIndex, action.payload.newIndex)
      })

    case CategoriesActions.sortCategoriesRequest().type:
      return state.merge({
        savingSortCategories: true,
        savingSortCategoriesError: null,
        savedSortCategories: false,
      })
    case CategoriesActions.sortCategoriesSuccess().type:
      return state.merge({
        savingSortCategories: false,
        savingSortCategoriesError: null,
        savedSortCategories: true,
      })
    case CategoriesActions.sortCategoriesFailed().type:
      return state.merge({
        savingSortCategories: false,
        savingSortCategoriesError: action.payload.error,
        savedSortCategories: false,
      })
    default: return state
  }
}
