import React from 'react'
import { useField } from 'react-form'
import validate from 'validate.js'
import clsx from 'clsx'

const TextField = ({ name = '', placeholder = '', validate }) => {
  const {
    meta: { error, isTouched, isValidating },
    getInputProps
  } = useField(name, {
    validate: value => {
      return validate(value)
    }
  })

  return (
    <>
      <input
        {...getInputProps()}
        className={ clsx("input", error ? "is-danger" : null ) }
        type="text"
        placeholder={placeholder} />
    </>
  )
}

export default TextField
