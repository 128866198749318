import { create } from 'apisauce'
import api from './'

export const getEps = () => {
  const externalApi = create({ baseURL: 'https://www.datos.gov.co'})
  return externalApi.get('resource/kxmz-h4yh.json')
}

export const getWorker = token => {
  api.setHeader('Authorization', 'Bearer ' + token )
  return api.get('/auth/worker')
}

export const patchCurrentWorker = data => {
  return api.patch('auth/worker', data)
}

export const getWorkers = (statusFilter, currentPage) => {
  if (statusFilter && statusFilter !== "all") {
    return api.get('/workers?status=' + statusFilter + '&limit=10' + (currentPage ? '&page=' + currentPage : ''))
  }
  return api.get('/workers?limit=10' + (currentPage ? '&page=' + currentPage : ''))
}

export const getWorkerById = id => {
  return api.get('/workers/' + id )
}

export const patchWorker = worker => {
  return api.patch('/workers/' + worker._id, worker)
}

export const approveWorker = worker => {
  return api.post('/workers/' + worker._id + '/approve')
}

export const rejectWorker = worker => {
  return api.post('/workers/' + worker._id + '/reject')
}
