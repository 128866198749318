import React, { useEffect } from 'react'

import { routes } from './Navigation/router'

import AuthActions from './Redux/Auth/actions'

import { useDispatch, useSelector } from 'react-redux'

import 'bulma/css/bulma.min.css'

import './index.sass'

import 'font-awesome/css/font-awesome.min.css'

const App = () => {

  const dispatch = useDispatch()
  const authLoading = useSelector( state => state.auth.loading )
  const isAuthenticated = useSelector( state => state.auth.access_token !== null && state.auth.user !== null )

  useEffect( () => {
    dispatch( AuthActions.restoreAuth() )
  }, [dispatch])

  return (
    <main role="application">
      { routes( isAuthenticated, authLoading ) }
    </main>
  )
}

export default App
