import React, { useState, useEffect } from 'react'

import NavigationTopBar from '../../Components/NavigationTopBar'
import Crud from '../../Components/Crud'

// import WorkServicesList from '../../Components/WorkServices/WorkServicesList'
// import WorkServiceModal from '../../Components/WorkServices/WorkServiceModal'

import WorkServiceForm from './form'

import { useDispatch, useSelector } from 'react-redux'
import WorkServicesActions from '../../Redux/WorkServices/actions'
import CategoriesActions from '../../Redux/Categories/actions'


import i18n from 'i18next'

import styles from './Styles/list.module.sass'
import clsx from 'clsx'

const newWorkService = {
  name: '',
  color: '',
  category: {},
  icon_url: '',
  rate: {
    fulltime: '',
    midtime: '',
    hours: '',
    consultation: '',
  }
}

const WorkServices = () => {

  const dispatch = useDispatch()
  const {
    data,
    loading,
    error,
    sortedWorkServices,
    sortingWorkServices,
    updatedWorkService,
    updatingWorkService,
    } = useSelector( state => state.workServices )

  const activeCategories = useSelector( state => state.categories.active )

  const [ showWorkServiceModal, setShowWorkServiceModal ] = useState(false)
  const [ workService, setWorkService ] = useState(null)

  useEffect( () => {
    dispatch( WorkServicesActions.getWorkServices() )
    dispatch( CategoriesActions.loadCategories() )
  }, [])

  return (
    <div>
      <NavigationTopBar selected={2} />
      <div className="container">
        <div className={ styles.container }>
          <Crud
            name="servicio"
            items={data}
            saved={sortedWorkServices || updatedWorkService}
            saving={sortingWorkServices || updatingWorkService }
            onChangeCurrentItem={ item => setWorkService(item) }
            onChangeOrder={ (oldIndex, newIndex) => {
              dispatch( WorkServicesActions.changeOrderWorkServices(oldIndex, newIndex) )
            }}
            onChangeActive={ (status, item) => {
              dispatch( WorkServicesActions.update({ ...item, status: status ? "active" : "inactive" }) )
            }}
            onSaveOrder={ () => dispatch( WorkServicesActions.sort() ) }
            onDelete={ item => dispatch( WorkServicesActions.delete(item) ) }
            onEdit={ item => console.log('onEdit:', item) }
            renderItem={ item => (
              <div>
                { item.name }
              </div>
            )}
            renderForm={ () => <WorkServiceForm
                                  availableCategories={activeCategories.asMutable()}
                                  values={workService || newWorkService}
                                  loading={updatingWorkService}
                                  onSubmit={ values => {
                                    if (workService) {
                                      dispatch( WorkServicesActions.update(values) )
                                    }else {
                                      dispatch( WorkServicesActions.create(values) )
                                    }
                                  }} /> } />
        </div>
      </div>
    </div>
  )
}


export default WorkServices
