import { createActions } from 'redux-actions'

import {
  getEps,
  getWorkers,
  getWorkerById,
  patchWorker,
  approveWorker,
  rejectWorker,
} from '../../Services/Worker'

import {
  EPSError,
  FSError,
} from '../../Utils/errors'

const WorkerActions = createActions({
  LOAD_EPS_REQUEST: () => {},
  LOAD_EPS_SUCCESS: options => ({ options }),
  LOAD_EPS_FAILED: error => ({ error }),

  LOAD_WORKERS_REQUEST: () => {},
  LOAD_WORKERS_SUCCESS: (workers, pagination) => ({ workers, pagination }),
  LOAD_WORKERS_FAILED: error => ({ error }),

  LOAD_WORKER_REQUEST: () => {},
  LOAD_WORKER_SUCCESS: worker => ({ worker }),
  LOAD_WORKER_FAILED: error => ({ error }),

  CHANGE_WORKER_STATUS_REQUEST: () => {},
  CHANGE_WORKER_STATUS_SUCCESS: worker => ({ worker }),
  CHANGE_WORKER_STATUS_FAILED: error => ({ error }),

  RESET_WORKER: () => {},

})

WorkerActions.getEps = () => {
  return async dispatch => {
    dispatch( WorkerActions.loadEpsRequest() )

    const data = [
      {
        id: 1,
        label: 'COMPENSAR',
      },
      {
        id: 2,
        label: 'SANITAS',
      },
      {
        id: 3,
        label: 'SALUD TOTAL',
      },
      {
        id: 4,
        label: 'FAMISANAR'
      },

      {
        id: 5,
        label: 'SURA'
      },
      {
        id: 6,
        label: 'NUEVA EPS'
      },

    ]

    dispatch( WorkerActions.loadEpsSuccess(data) )
  }
}


WorkerActions.getWorkers = (statusFilter, currentPage) => {
  return async dispatch => {
    dispatch( WorkerActions.loadWorkersRequest())
    const response = await getWorkers(statusFilter, currentPage)
    console.log('get workers: ', response)
    if (!response.ok) {
      return dispatch( WorkerActions.loadWorkersFailed( new FSError(400, 'workers API not responding') ) )
    }
    const pagination = {
      page: response.data.page,
      total: response.data.total,
      limit: response.data.limit,
      totalPages: response.data.totalPages,
    }
    dispatch( WorkerActions.loadWorkersSuccess(response.data.data, pagination) )
  }
}

WorkerActions.getWorker = id => {
  return async dispatch => {
    dispatch( WorkerActions.loadWorkerRequest())
    const response = await getWorkerById(id)
    console.log('get worker: ', response)
    if (!response.ok) {
      return dispatch( WorkerActions.loadWorkerFailed( new FSError(400, 'workers API not responding') ) )
    }
    dispatch( WorkerActions.loadWorkerSuccess(response.data.data) )
  }
}

WorkerActions.approveWorker = worker => {
  return async dispatch => {
    dispatch( WorkerActions.changeWorkerStatusRequest())
    const response = await approveWorker(worker)
    console.log('update worker status: ', response)
    if (!response.ok) {
      return dispatch( WorkerActions.changeWorkerStatusFailed( new FSError(400, 'workers API not responding') ) )
    }
    dispatch( WorkerActions.changeWorkerStatusSuccess(response.data.data) )
  }
}

WorkerActions.rejectWorker = worker => {
  return async dispatch => {
    dispatch( WorkerActions.changeWorkerStatusRequest())
    const response = await rejectWorker(worker)
    console.log('update worker status: ', response)
    if (!response.ok) {
      return dispatch( WorkerActions.changeWorkerStatusFailed( new FSError(400, 'workers API not responding') ) )
    }
    dispatch( WorkerActions.changeWorkerStatusSuccess(response.data.data) )
  }
}


export default WorkerActions
