import I18n from 'i18next'

export const ForbiddenError = function() {
  this.code = 403
  this.message = I18n.t('ERROR_FORBIDDEN')
  this.debugMessage = 'User scopes not include admin or superadmin'
}

export const EPSError = function() {
  this.code = 404
  this.message = I18n.t('ERROR_EPS')
  this.debugMessage = 'something went wrong with https://www.datos.gov.co/resource/kxmz-h4yh.json'
}

export const FSError = function(code, debugMessage) {
  this.code = code
  this.message = I18n.t('ERROR_GENERAL')
  this.debugMessage = debugMessage
}
