import { createActions } from 'redux-actions'

import {
  getWorkServices as getWorkServicesAPI,
  postWorkService,
  deleteWorkService,
  putWorkService,
  sortWorkServices,
} from '../../Services/WorkServices'

const WorkServicesActions = createActions({
  GET_WORK_SERVICES_REQUEST: () => {},
  GET_WORK_SERVICES_RESPONSE: response => ({ response }),

  CHANGE_ORDER_WORK_SERVICES: (oldIndex, newIndex) => ({ oldIndex, newIndex }),

  POST_WORK_SERVICE_REQUEST: () => {},
  POST_WORK_SERVICE_RESPONSE: response => ({ response }),

  GET_WORK_SERVICE_REQUEST: () => {},
  GET_WORK_SERVICE_RESPONSE: response => ({ response }),

  PUT_WORK_SERVICE_REQUEST: workService => ({ workService }),
  PUT_WORK_SERVICE_RESPONSE: response => ({ response }),

  DELETE_WORK_SERVICE_REQUEST: () => {},
  DELETE_WORK_SERVICE_RESPONSE: response => ({ response }),

  SORT_WORK_SERVICES_REQUEST: () => {},
  SORT_WORK_SERVICES_RESPONSE: error => ({ error }),
})

WorkServicesActions.getWorkServices = query => {
  return async dispatch => {
    dispatch( WorkServicesActions.getWorkServicesRequest() )
    const response = await getWorkServicesAPI(query)
    console.log('Get work services response: ', response)
    if (!response.ok) {
      return dispatch( WorkServicesActions.getWorkServicesResponse({ error: response.data.error }) )
    }
    dispatch( WorkServicesActions.getWorkServicesResponse({ data: response.data }) )
  }
}

WorkServicesActions.create = workService => {
  return async dispatch => {
    dispatch( WorkServicesActions.postWorkServiceRequest() )
    const response = await postWorkService(workService)
    console.log('Post Work Service response ', response)
    if (!response.ok) {
      return dispatch( WorkServicesActions.postWorkServiceResponse({ error: response.data.error }) )
    }
    dispatch( WorkServicesActions.postWorkServiceResponse({ data: response.data }) )
  }
}

WorkServicesActions.delete = workService => {
  return async dispatch => {
    dispatch( WorkServicesActions.deleteWorkServiceRequest() )
    const response = await deleteWorkService(workService)
    console.log('Delete Work Service response ', response)
    if (!response.ok) {
      return dispatch( WorkServicesActions.deleteWorkServiceResponse({ error: response.data.error }) )
    }
    dispatch( WorkServicesActions.deleteWorkServiceResponse({ data: workService }) )
  }
}

WorkServicesActions.update = workService => {
  return async dispatch => {
    dispatch( WorkServicesActions.putWorkServiceRequest(workService) )
    const response = await putWorkService(workService)
    console.log('Update Work Service response ', response)
    if (!response.ok) {
      return dispatch( WorkServicesActions.putWorkServiceResponse({ error: response.data.error }) )
    }
    dispatch( WorkServicesActions.putWorkServiceResponse({ data: response.data }) )
  }
}

WorkServicesActions.sort = () => {
  return async (dispatch, getState) => {
    const sort = getState().workServices.data
                  .map( (service, idx ) => ({
                    _id: service._id,
                    order: idx,
                  }))
    dispatch( WorkServicesActions.sortWorkServicesRequest() )
    const response = await sortWorkServices(sort)
    console.log('Sort Work Services response ', response)
    if (!response.ok) {
      return dispatch( WorkServicesActions.sortWorkServicesResponse(response.data.error) )
    }
    dispatch( WorkServicesActions.sortWorkServicesResponse() )
  }
}

export default WorkServicesActions
