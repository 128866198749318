import React from 'react'

import NavigationTopBar from '../Components/NavigationTopBar'

const HomeScreen = () => {
  return (
    <div>
      <NavigationTopBar />
    </div>
  )
}


export default HomeScreen
