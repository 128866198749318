import React from 'react'

import i18n from 'i18next'

import {
  isValidPassword,
 } from '../../Services/Validations'

import AccountActions from '../../Redux/Account/actions'
import NavigationTopBar from '../../Components/NavigationTopBar'
import Loader from '../../Components/Loader'

import { useDispatch, useSelector } from 'react-redux'

const ChangePasswordScreen = ({ history }) => {

  const [ password, setPassword ] = React.useState('')
  const [ newPassword, setNewPassword ] = React.useState('')
  const [ confirmNewPassword, setConfirmNewPassword ] = React.useState('')

  const [ invalidPassword, setInvalidPassword ] = React.useState(false)
  const [ passwordMismatch, setPasswordMismatch ] = React.useState(false)
  const [ showSuccessMessage, setShowSuccessMessage ] = React.useState(false)
  const [ showErrorMessage, setShowErrorMessage ] = React.useState(false)

  const dispatch = useDispatch()

  const {
    changePasswordLoading,
    changePasswordError,
    changedPassword
  } = useSelector( state => state.account )

  React.useEffect( () => {
    if (changedPassword) {
      setShowSuccessMessage( true )
      setPassword('')
      setNewPassword('')
      setConfirmNewPassword('')
    }
  }, [changedPassword])

  React.useEffect( () => {
    if (changePasswordError) {
      setShowErrorMessage(true)
    }
  }, [changePasswordError])


  function checkPassword(typedNewPassword, strength) {
    setNewPassword(typedNewPassword)
    setInvalidPassword( isValidPassword(typedNewPassword, strength) )
  }

  function checkConfirmPassword(typedConfirmMewPassword) {
    setConfirmNewPassword(typedConfirmMewPassword)
    setPasswordMismatch( typedConfirmMewPassword !== newPassword )
  }


  function change() {
    if (invalidPassword || passwordMismatch) return
    dispatch( AccountActions.changePassword(password, newPassword) )
  }

  return (
    <div>
      <div>
        <NavigationTopBar />
        <br/>
        <div className="columns is-centered">
          <div className="column is-4">

            <div className="title">
              { i18n.t("changePassword") }
            </div>

            <div className="field">
              <label className="label">{ i18n.t("password") }</label>
              <div className="control">
                <input
                  className="input"
                  value={ password }
                  onChange={ event => setPassword(event.target.value) }
                  type="password"
                  placeholder={ i18n.t("password") } />
              </div>
            </div>

            <div className="field">
              <label className="label">{ i18n.t("newPassword") }</label>
              <div className="control">
                <input
                  className="input"
                  value={ newPassword }
                  onChange={ event => checkPassword(event.target.value, "strong") }
                  type="password"
                  placeholder={ i18n.t("newPassword") } />
              </div>
              { invalidPassword && newPassword.length > 4 &&
                <span className="has-text-danger">{ i18n.t("invalidPasswordStrong") }</span> }
            </div>

            <div className="field">
              <label className="label">{ i18n.t("newPassword") }</label>
              <div className="control">
                <input
                  className="input"
                  value={ confirmNewPassword }
                  onChange={ event => checkConfirmPassword(event.target.value) }
                  type="password"
                  placeholder={ i18n.t("newPassword") } />
              </div>
              { passwordMismatch && confirmNewPassword.length > 4 &&
                 <span className="has-text-danger">{ i18n.t("passwordMismatch") }</span> }
            </div>

            <div className="field">
              <div className="control">
                <button
                  onClick={ () => change() }
                  className="button is-link">
                  { i18n.t("save") }
                  </button>
              </div>
            </div>

            { changePasswordLoading && (
              <Loader />
            )}

            { showSuccessMessage && (
              <div className="message is-primary">
                <div className="message-header">
                  <button
                    className="delete"
                    aria-label="delete"
                    onClick={ () => setShowSuccessMessage(false) }>
                  </button>
                </div>
                <div className="message-body">
                  { i18n.t("changedPassword") }
                </div>
              </div>
            )}

            { changePasswordError && showErrorMessage && (
              <div className="message is-danger">
                <div className="message-header">
                  <button
                    className="delete"
                    aria-label="delete"
                    onClick={ () => setShowErrorMessage(false) }>
                  </button>
                </div>
                <div className="message-body">
                  { changePasswordError.message }
                </div>
              </div>
            )}

          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePasswordScreen
