import api from './'

export const getWorkServices = query => {
  return api.get('work-services/', query)
}

export const getWorkService = id => {
  return api.get('work-services/' + id)
}

export const postWorkService = workService => {
  return api.post('work-services/', { ...workService })
}

export const putWorkService = workService => {
  return api.put('work-services/' + workService._id, { ...workService })
}

export const deleteWorkService = workService => {
  return api.delete('work-services/' + workService._id)
}

export const sortWorkServices = sort => {
  return api.post('work-services/sort', sort)
}
