import React, { useEffect, useState } from 'react'

import NavigationTopBar from '../../Components/NavigationTopBar'
import Crud from '../../Components/Crud'

import { useDispatch, useSelector } from 'react-redux'
import CategoriesActions from '../../Redux/Categories/actions'

import CategoryForm from './form'

import styles from './styles/index.module.sass'

const newCategory = {
  name: null
}

const Categories = () => {

  const dispatch = useDispatch()

  const [currentCategory, setCurrentCategory] = useState(null)

  const allCategories = useSelector( state => state.categories.all)
  const updatingCategory = useSelector( state => state.categories.updatingCategory)
  const updatedCategory = useSelector( state => state.categories.updatedCategory)

  const savingSortCategories = useSelector( state => state.categories.savingSortCategories)
  const savedSortCategories = useSelector( state => state.categories.savedSortCategories)

  useEffect( () => {
    dispatch( CategoriesActions.loadCategories() )
  }, [dispatch])

  return (
    <div>
      <NavigationTopBar selected={1} />
      <div className="container">
        <Crud
          sortable
          name="categoría"
          items={allCategories}
          saved={updatedCategory || savedSortCategories}
          saving={updatingCategory || savingSortCategories}
          onChangeCurrentItem={ item => setCurrentCategory(item) }
          onChangeOrder={ (oldIndex, newIndex) => {
            dispatch( CategoriesActions.changeOrderCategories(oldIndex, newIndex) )
          }}
          onChangeActive={ (status, item) => {
            dispatch( CategoriesActions.update({ ...item, status: status ? "active" : "inactive" }) )
          }}
          onDelete={ item => dispatch( CategoriesActions.delete(item) ) }
          onSaveOrder={ () => dispatch( CategoriesActions.saveCurrentSort() )}
          renderItem={ item => (
            <div>
              {item.name}
            </div>
          )}
          renderForm={ () => {
            return (
              <CategoryForm
                values={ currentCategory || newCategory }
                onSubmit={ values => {
                  if (currentCategory) {
                    dispatch( CategoriesActions.update(values) )
                  } else {
                    dispatch( CategoriesActions.create(values) )
                  }
                }} />
            )
          } } />
      </div>
    </div>
  )
}

export default Categories
