import React, { useEffect, useState, Fragment } from 'react'

import NavigationTopBar from '../../Components/NavigationTopBar'

import Pager from '../../Utils/pagination'

import WorkerActions from '../../Redux/Worker/actions'

import { useDispatch, useSelector } from 'react-redux'

import clsx from 'clsx'

const WorkerRequests = ({ history }) => {

  const dispatch = useDispatch()
  const workers = useSelector( state => state.worker.workers )
  const workersPagination = useSelector( state => state.worker.workersPagination )
  const [pagination, setPagination] = useState(null)
  const [currentStatusFilter, setCurrentStatusFilter] = useState('all')
  const [currentPage, setCurrentPage] = useState(0)

  useEffect( () => {
    setPagination(Pager(workersPagination))
  }, [workersPagination])

  useEffect( () => {
    dispatch(WorkerActions.getWorkers(currentStatusFilter, currentPage ))
  }, [currentStatusFilter, currentPage])

  useEffect( () => {
    setCurrentPage(0)
  }, [currentStatusFilter])

  function checkTag(worker) {
    switch (worker.status) {
      case 'pending':
      case 'incomplete':
        return 'is-info'
      case 'completed':
        return 'is-warning'
      case 'rejected':
        return 'is-danger'
      case 'active':
        return 'is-success'
      default: return ''
    }
  }

  return (
    <div>
      <NavigationTopBar selected={3} />
      <div className="screen-container">
        <div className="container">
        <div className="columns">
          <div className="column">
            <section className="hero">
              <div className="hero-body">
                <div className="container">
                  <div className="buttons has-addons">
                    <button
                      className={ clsx("button", currentStatusFilter === "all" ? "is-dark is-selected" : null )}
                      onClick={ () => setCurrentStatusFilter('all') }>
                      Todos
                    </button>
                    <button
                      className={ clsx("button", currentStatusFilter === "completed" ? "is-dark is-selected" : null )}
                      onClick={ () => setCurrentStatusFilter('completed') }>
                      Por aprobar
                    </button>
                    <button
                      className={ clsx("button", currentStatusFilter === "active" ? "is-dark is-selected" : null )}
                      onClick={ () => setCurrentStatusFilter('active') }>
                      Aprobados
                    </button>
                    <button
                      className={ clsx("button", currentStatusFilter === "incomplete" ? "is-dark is-selected" : null )}
                      onClick={ () => setCurrentStatusFilter('incomplete') }>
                      En Proceso
                    </button>
                    <button
                      className={ clsx("button", currentStatusFilter === "rejected" ? "is-dark is-selected" : null) }
                      onClick={ () => setCurrentStatusFilter('rejected') }>
                      No Aprobados
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
          <div className="columns">
            <div className="column">
              <table className="table is-fullwidth">
                <thead>
                  <tr>
                    <th>
                      Nombres
                    </th>
                    <th>
                      Apellidos
                    </th>
                    <th>
                      Estado
                    </th>
                    <th>

                    </th>
                  </tr>
                </thead>
                <tbody>
                  { workers?.map( worker => (
                    <tr onClick={ () => history.push('/worker/requests/' + worker._id ) } key={worker._id}>
                      <td>
                        { worker.user.firstname }
                      </td>
                      <td>
                        { worker.user.lastname }
                      </td>
                      <td>
                        <div className={clsx("tag", checkTag(worker))}>
                          { worker.statusLabel }
                        </div>
                      </td>
                      <td>
                        ...
                      </td>
                    </tr>
                  )) }
                </tbody>
              </table>
            </div>
          </div>
          { pagination && pagination.totalPages > 1 && (
            <div className="columns">
              <div className="column">
                <nav className="pagination is-rounded" role="navigation" aria-label="pagination">
                  { pagination.currentPage !== 1 && (
                    <a
                      className="pagination-previous"
                      onClick={ () => setCurrentPage(currentPage - 1)}>
                      {"<"}
                    </a>
                  )}
                  <ul className="pagination-list">
                    { pagination.pages[0] !== 1 && (
                      <li>
                        <a
                          className={clsx("pagination-link", pagination.currentPage === 1 ? "is-current" : null )}
                          onClick={ () => setCurrentPage(0)}>
                          1
                        </a>
                      </li>
                    )}
                    { pagination.pages[0] !== 1 && pagination.pages.length > pagination.maxPages && (
                        <li><span className="pagination-ellipsis">&hellip;</span></li>
                    )}
                    { pagination.pages.map( page => (
                      <li key={page}>
                        <a
                          className={clsx("pagination-link", pagination.currentPage === page ? "is-current" : null )}
                          onClick={ () => setCurrentPage(page - 1)}>
                          {page}
                        </a>
                      </li>
                    ))}

                    { pagination.pages[pagination.pages.length - 1] !== pagination.totalPages - 1 &&
                        pagination.pages[pagination.pages.length - 1] !== pagination.totalPages && (
                      <li><span className="pagination-ellipsis">&hellip;</span></li>
                    )}

                    { pagination.totalPages > pagination.maxPages &&
                      pagination.pages[pagination.pages.length - 1] !== pagination.totalPages && (
                      <li>
                        <a
                          className="pagination-link"
                          onClick={ () => setCurrentPage(workersPagination.totalPages - 1)}>
                          { workersPagination.totalPages }
                        </a>
                      </li>
                    )}
                  </ul>
                  { pagination.currentPage !== pagination.totalPages && (
                    <a
                      className="pagination-next"
                      onClick={ () => setCurrentPage(currentPage + 1)}>
                      {">"}
                    </a>
                  )}
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default WorkerRequests
