import React from 'react'

import Loader from '../../Components/Loader'

import AccountActions from '../../Redux/Account/actions'

import {
  isValidEmail,
  isValidPassword,
 } from '../../Services/Validations'

import { useDispatch, useSelector } from 'react-redux'

import './Styles/SignUpScreenStyles.css'

import i18n from 'i18next'

const SignUpScreen = ({ history }) => {

  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [invalidEmail, setInvalidEmail] = React.useState(false)
  const [invalidPassword, setInvalidPassword] = React.useState(false)
  const [passwordMismatch, setPasswordMismatch] = React.useState(false)

  const { signUpLoading, signUpError, signedUp } = useSelector( state => state.account )
  const dispatch = useDispatch()

  React.useEffect( () => {
    if (signedUp) {
      history.replace('/home')
    }
  }, [signedUp])

  function signUp() {

    if (invalidEmail || invalidPassword || passwordMismatch)
      return

    dispatch( AccountActions.signUp(email, password) )
  }

  function checkEmail(email) {
    setEmail( email )
    setInvalidEmail( isValidEmail(email) )
  }

  function checkPassword(typedPassword, strength) {
    setPassword( typedPassword )
    setInvalidPassword( isValidPassword(typedPassword, strength) )
  }

  function checkConfirmPassword(typedConfirmPassword) {
    setConfirmPassword(typedConfirmPassword)
    setPasswordMismatch( typedConfirmPassword !== password )
  }

  function showLoginScreen() {
    history.replace('/')
  }

  return (
    <div className="columns">
      <div className="column">
        { signUpError && (
          <div className="columns">
            <div className="column is-4 is-offset-4">
              <div className="notification is-danger has-text-centered">
              { signUpError.message }
              </div>
            </div>
          </div>
        )}
        <div className="columns">
          <div className="column is-4 is-offset-4" id="signup-form">

            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label">{ i18n.t("email") }</label>
                  <div className="control">
                    <input
                      className="input"
                      required
                      type="text"
                      placeholder={ i18n.t("email") }
                      value={ email }
                      onChange={ event => checkEmail(event.target.value) } />
                  </div>
                  { invalidEmail && email.length > 4 &&
                    <span className="has-text-danger">{ i18n.t("invalidEmail") }</span> }
                </div>
                <div className="field">
                  <label className="label">{ i18n.t("password") }</label>
                  <div className="control">
                    <input
                      className="input"
                      required
                      type="password"
                      placeholder={ i18n.t("password") }
                      value={ password }
                      onChange={ event => checkPassword(event.target.value, "strong") } />
                  </div>
                  { invalidPassword && password.length > 4 &&
                    <span className="has-text-danger">{ i18n.t("invalidPasswordStrong") }</span> }
                </div>
                <div className="field">
                  <label className="label">{ i18n.t("confirmPassword") }</label>
                  <div className="control">
                    <input
                      className="input"
                      required
                      type="password"
                      placeholder={ i18n.t("password") }
                      value={ confirmPassword }
                      onChange={ event => checkConfirmPassword(event.target.value ) } />
                  </div>
                  { passwordMismatch && confirmPassword.length > 4 &&
                     <span className="has-text-danger">{ i18n.t("passwordMismatch") }</span> }
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column is-full">
                <a
                  onClick={ () => signUp() }
                  className="button is-link">
                    { i18n.t("signUp") }
                </a>
              </div>
              { signUpLoading && (
                <div className="column is-paddingless">
                  <Loader />
                </div>
              )}
            </div>

            <div className="columns">
              <div className="column">
                { i18n.t("alreadyRegistered") }
                <a href="#"
                  onClick={ () => showLoginScreen() }>
                   { " " + i18n.t("login") }
                </a>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUpScreen
