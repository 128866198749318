import api from './'

export const getCategories = () => {
  return api.get('categories')
}

export const postCategory = params => {
  return api.post('categories', params)
}

export const deleteCategory = id => {
  return api.delete(`categories/${id}`)
}

export const putCategory = (id, params) => {
  return api.put(`categories/${id}`, params)
}

export const sortCategories = sort => {
  return api.post('categories/sort', sort)
}
