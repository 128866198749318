import api from './'

export function setAuthorizationToken(token) {
  if (token) {
    localStorage.setItem('access_token', token)
    api.setHeader('Authorization', 'Bearer ' + token)
  }else {
    localStorage.setItem('access_token', null)
    delete api.headers['Authorization']
  }

}

export function restoreAuthorizationToken() {
  const token = localStorage.getItem('access_token')
  if ( token ) {
    api.setHeader('Authorization', 'Bearer ' + token)
  }
}

export function removeCurrentAuthorizationToken() {
  delete api.headers['Authorization']
}

export function login(email, password) {
  return api.post('auth/login', { email, password } )
}

export function signup(userAttributes) {
  return api.post('auth/signup', userAttributes)
}

export function me() {
  return api.get('auth/me')
}

export function updateProfile(params) {
  return api.put('/auth/me', params)
}

export function changePassword(oldPassword, newPassword) {
  return api.patch('/auth/change-password', { oldPassword, newPassword })
}

export function forgotPassword(email) {
  return api.post('/auth/forgot-password', { email })
}
