import React from 'react'


const Thanks = () => {
  return (
    <div className="container">
      <section className="hero">
        <div className="hero-body">
          <div className="container">
            <div style={{ textAlign: 'center', marginBottom: 40 }}>
              <img
                style={{ width: 120, height: 120 }}
                src={ require('../../Assets/img/logo.png') } alt="logo" />
            </div>
            <h1 className="title">
              Gracias por querer ser parte de nuestro equipo
            </h1>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <h2 className="subtitle">
            Revisaremos tus datos y te avisaremos cuando puedas empezar a tomar servicios
          </h2>
        </div>
      </section>
    </div>
  )
}


export default Thanks
