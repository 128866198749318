import { createActions } from 'redux-actions'

import { login as loginService,
          me as meService,
          signup as signupService,
          updateProfile as updateProfileService,
          changePassword as changePasswordService,
          forgotPassword as forgotPasswordService,
        } from '../../Services/Account'

import {
  getWorker,
  patchCurrentWorker,
} from '../../Services/Worker'

import moment from 'moment'

import { ForbiddenError } from '../../Utils/errors'

import AuthActions from '../Auth/actions'

const AccountActions = createActions({
  LOGIN_REQUEST: () => {},
  LOGIN_RESPONSE: response => ({ response }),

  SIGN_UP_REQUEST: () => {},
  SIGN_UP_RESPONSE: response => ({ response }),

  UPDATE_PROFILE_REQUEST: () => {},
  UPDATE_PROFILE_RESPONSE: response => ({ response }),

  CHANGE_PASSWORD_REQUEST: () => {},
  CHANGE_PASSWORD_RESPONSE: response => ({ response }),

  FORGOT_PASSWORD_REQUEST: () => {},
  FORGOT_PASSWORD_RESPONSE: response => ({ response }),

  WORKER_REGISTRATION_STATUS_REQUEST: () => {},
  WORKER_REGISTRATION_STATUS_RESPONSE: response => ({ response }),

  WORKER_UPDATE_REQUEST: () => {},
  WORKER_UPDATE_RESPONSE: response => ({ response }),
})

AccountActions.login = (email, password) => {
  return async dispatch => {

    dispatch( AccountActions.loginRequest() )

    const loginResponse = await loginService(email, password)

    console.log('loginResponse: ', loginResponse)

    if (!loginResponse.ok)
      return dispatch( AccountActions.loginResponse( { error: loginResponse.data.error } ) )


    dispatch( AuthActions.setAccessToken(loginResponse.data.access_token) )

    const meResponse = await meService()

    console.log('meResponse: ', meResponse)

    if (!meResponse.ok)
      return dispatch( AccountActions.loginResponse( { error: meResponse.data.message } ) )

    if (!meResponse.data.data.scopes) {
      dispatch( AccountActions.logOut() )
      return dispatch( AccountActions.loginResponse( { error: new ForbiddenError() } ) )
    }

    if (meResponse.data.data.scopes.includes('superadmin') || meResponse.data.data.scopes.includes('admin')) {
      dispatch(
        AccountActions.loginResponse( {
          data: meResponse.data.data
        })
      )

      dispatch( AuthActions.setUser(meResponse.data.data) )

    }else {
      dispatch( AuthActions.logOut() )
      return dispatch( AccountActions.loginResponse( { error: new ForbiddenError() } ) )
    }

  }
}


AccountActions.signUp = (email, password) => {
  return async dispatch => {

    dispatch( AccountActions.signUpRequest() )

    const newUserAttributes = {
      email,
      password,
    }

    const signUpResponse = await signupService(newUserAttributes)

    console.log('signupResponse: ', signUpResponse)
    if (!signUpResponse.ok)
      return dispatch( AccountActions.signUpResponse( { error: signUpResponse.data.error } ) )

    dispatch( AuthActions.setAccessToken(signUpResponse.data.access_token) )

    // save token
    const meResponse = await meService()

    console.log('meResponse: ', meResponse)

    if (!meResponse.ok)
      return dispatch( AccountActions.signUpResponse( { error: meResponse.data.message } ) )

    if (!meResponse.data.data.scopes) {
      dispatch( AccountActions.logOut() )
      return dispatch( AccountActions.signUpResponse( { error: "without scope" } ) )
    }

    if (meResponse.data.data.scopes.includes('superadmin') || meResponse.data.data.scopes.includes('admin')) {
      dispatch(
        AccountActions.signUpResponse( {
          data: meResponse.data.data
        })
      )

      dispatch( AuthActions.setUser(meResponse.data.data) )

    }else {
      dispatch( AuthActions.logOut() )
      return dispatch( AccountActions.signUpResponse( { error: new ForbiddenError() } ) )
    }

  }
}

AccountActions.updateProfile = userParams => {
  return async dispatch => {

    dispatch( AccountActions.updateProfileRequest() )

    const updateProfileResponse = await updateProfileService(userParams)

    console.log('updateProfileResponse: ', updateProfileResponse)
    if (!updateProfileResponse.ok)
      return dispatch( AccountActions.updateProfileResponse( { error: updateProfileResponse.data.error } ) )

    dispatch(
      AccountActions.updateProfileResponse( {
        data: updateProfileResponse.data.data,
      }) )

    dispatch( AuthActions.setUser(updateProfileResponse.data.data) )
  }
}

AccountActions.changePassword = (oldPassword, newPassword) => {
  return async dispatch => {

    dispatch( AccountActions.changePasswordRequest() )

    const changePasswordResponse = await changePasswordService(oldPassword, newPassword)

    console.log('changePasswordResponse: ', changePasswordResponse)
    if (!changePasswordResponse.ok)
      return dispatch( AccountActions.changePasswordResponse( { error: changePasswordResponse.data.error } ) )

    dispatch(
      AccountActions.changePasswordResponse( {
        data: changePasswordResponse.data.data,
      }) )
  }
}

AccountActions.forgotPassword = email => {
  return async dispatch => {

    dispatch( AccountActions.forgotPasswordRequest() )

    const forgotPasswordResponse = await forgotPasswordService(email)

    console.log('forgotPasswordResponse: ', forgotPasswordResponse)
    if (!forgotPasswordResponse.ok)
      return dispatch( AccountActions.forgotPasswordResponse( { error: forgotPasswordResponse.data.error } ) )

    dispatch(
      AccountActions.forgotPasswordResponse( {
        data: forgotPasswordResponse.data.data,
      }) )
  }
}

AccountActions.logOut = () => {
  return dispatch => {
    dispatch( AuthActions.removeAuth() )
  }
}

AccountActions.workerRegistrationStatus = token => {
  return async dispatch => {
    dispatch( AccountActions.workerRegistrationStatusRequest() )
    const response = await getWorker(token)
    console.log('worker registration status response ', response)
    if (!response.ok && response.data)
      return dispatch( AccountActions.workerRegistrationStatusResponse({ error: response.data.error }) )
    else if (!response.ok)
      return dispatch( AccountActions.workerRegistrationStatusResponse({ error: 'network error' }) )

    console.log('worker registration status response data', response.data.data)
    const birthday = moment(response.data.data.birthday)
    let data = { ...response.data.data, birthday: birthday.format('YYYY-MM-DD') }
    delete data.worker

    dispatch( AccountActions.workerRegistrationStatusResponse(data) )
  }
}

AccountActions.workerUpdate = data => {
  return async dispatch => {
    dispatch( AccountActions.workerUpdateRequest() )
    const response = await patchCurrentWorker(data)
    console.log('worker registration status response ', response)
    if (!response.ok && response.data)
      return dispatch( AccountActions.workerUpdateResponse({ error: response.data.error }) )
    else if (!response.ok)
      return dispatch( AccountActions.workerUpdateResponse({ error: 'network error' }) )

    dispatch( AccountActions.workerUpdateResponse(response.data) )
  }
}

export default AccountActions
