import React from 'react'

import TextField from '../../../Components/Form/Inputs/textfield'

import { useForm } from 'react-form'

import validate from 'validate.js'

const constraints = {
  fullname: {
    presence: {allowEmpty: false},
  },
  phone: {
    presence: {allowEmpty: false},
  },
}


function WorkReferencesForm({ defaultValues, onSubmit, onCancel }) {
  const {
    Form,
    meta: { isSubmitting, canSubmit }
  } = useForm({
    onSubmit: onSubmit,
    debugForm: false,
    defaultValues,
  })

  return (
    <Form>
      <h3 className="subtitle">1.</h3>
      <div className="columns">
        <div className="column">
          Nombres completos
        </div>
      </div>
      <div className="columns">
        <div className="column is-4">
          <div className="field">
            <div className="control">
              <TextField
                name="fullnameFirst"
                placeholder="Nombres completos"
                validate={ value => validate({fullname: value}, constraints).fullname?.[0] || null } />
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          Teléfono
        </div>
      </div>
      <div className="columns">
        <div className="column is-4">
          <div className="field">
            <TextField
              name="phoneFirst"
              placeholder="Teléfono"
              validate={ value => validate({phone: value}, constraints).phone?.[0] || null } />
          </div>
        </div>
      </div>
      <h3 className="subtitle">2.</h3>
      <div className="columns">
        <div className="column">
          Nombres completos
        </div>
      </div>
      <div className="columns">
        <div className="column is-4">
          <div className="field">
            <div className="control">
            <TextField
              name="fullnameSecond"
              placeholder="Nombres completos"
              validate={ value => validate({fullname: value}, constraints).fullname?.[0] || null } />
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          Teléfono
        </div>
      </div>
      <div className="columns">
        <div className="column is-4">
          <div className="field">
            <TextField
              name="phoneSecond"
              placeholder="Teléfonos"
              validate={ value => validate({phone: value}, constraints).phone?.[0] || null } />
          </div>
        </div>
      </div>
      <h3 className="subtitle">3.</h3>
      <div className="columns">
        <div className="column">
          Nombres completos
        </div>
      </div>
      <div className="columns">
        <div className="column is-4">
          <div className="field">
            <div className="control">
            <TextField
              name="fullnameThird"
              placeholder="Nombres completos"
              validate={ value => validate({fullname: value}, constraints).fullname?.[0] || null } />
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          Teléfono
        </div>
      </div>
      <div className="columns">
        <div className="column is-4">
          <div className="field">
            <TextField
              name="phoneThird"
              placeholder="Teléfonos"
              validate={ value => validate({phone: value}, constraints).phone?.[0] || null } />
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="buttons">
            <button
              className="button is-success"
              type="submit"
              disabled={!canSubmit}>
              Siguiente
            </button>
            <button
              className="button is-info"
              onClick={ () => onCancel() } >
              Anterior
            </button>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default WorkReferencesForm
