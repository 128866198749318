import { createActions } from 'redux-actions'

import {
  getCategories,
  postCategory,
  deleteCategory,
  putCategory,
  sortCategories,
} from '../../Services/Categories'

const CategoriesActions = createActions({
  FETCH_CATEGORIES_REQUEST: () => {},
  FETCH_CATEGORIES_SUCCESS: categories => ({ categories }),
  FETCH_CATEGORIES_FAILED: error => ({ error }),

  POST_CATEGORY_REQUEST: () => {},
  POST_CATEGORY_SUCCESS: category => ({ category }),
  POST_CATEGORY_FAILED: error => ({ error }),

  DELETE_CATEGORY_REQUEST: () => {},
  DELETE_CATEGORY_SUCCESS: category => ({ category }),
  DELETE_CATEGORY_FAILED: error => ({ error }),

  PUT_CATEGORY_REQUEST: () => {},
  PUT_CATEGORY_SUCCESS: category => ({ category }),
  PUT_CATEGORY_FAILED: error => ({ error }),

  SORT_CATEGORIES_REQUEST: () => {},
  SORT_CATEGORIES_SUCCESS: category => ({ category }),
  SORT_CATEGORIES_FAILED: error => ({ error }),

  CHANGE_ORDER_CATEGORIES: (oldIndex, newIndex) => ({ oldIndex, newIndex }),

})


CategoriesActions.loadCategories = () => {
  return async dispatch => {
    try {
      dispatch( CategoriesActions.fetchCategoriesRequest() )
      const response = await getCategories()
      console.log('Get categories response: ', response)
      if (!response.ok) {
        return dispatch( CategoriesActions.fetchCategoriesFailed(response.data.error) )
      }
      dispatch( CategoriesActions.fetchCategoriesSuccess(response.data) )
    } catch (err) {
      dispatch( CategoriesActions.fetchCategoriesFailed(err) )
    }
  }
}

CategoriesActions.create = category => {
  return async dispatch => {
    try {
      dispatch( CategoriesActions.postCategoryRequest() )
      const response = await postCategory(category)
      console.log('Post category response: ', response)
      if (!response.ok) {
        return dispatch( CategoriesActions.postCategoryFailed(response.data.error) )
      }
      dispatch( CategoriesActions.postCategorySuccess(response.data) )
    } catch (err) {
      dispatch( CategoriesActions.postCategoryFailed(err) )
    }
  }
}

CategoriesActions.delete = category => {
  return async dispatch => {
    dispatch( CategoriesActions.deleteCategoryRequest() )
    const response = await deleteCategory(category._id)
    console.log('Delete Category response ', response)
    if (!response.ok) {
      return dispatch( CategoriesActions.deleteCategoryFailed(response.data.error) )
    }
    dispatch( CategoriesActions.deleteCategorySuccess(category) )
  }
}

CategoriesActions.update = category => {
  return async dispatch => {
    dispatch( CategoriesActions.putCategoryRequest() )
    const response = await putCategory(category._id, category)
    console.log('Put Category response ', response)
    if (!response.ok) {
      return dispatch( CategoriesActions.putCategoryFailed(response.data.error) )
    }
    dispatch( CategoriesActions.putCategorySuccess(category) )
  }
}

CategoriesActions.saveCurrentSort = () => {
  return async (dispatch, getState) => {
    const sort = getState().categories.all
                  .map( (service, idx ) => ({
                    _id: service._id,
                    order: idx,
                  }))
    dispatch( CategoriesActions.sortCategoriesRequest() )
    const response = await sortCategories(sort)
    console.log('Sort Categories response ', response)
    if (!response.ok) {
      return dispatch( CategoriesActions.sortCategoriesFailed(response.data.error) )
    }
    dispatch( CategoriesActions.sortCategoriesSuccess() )
  }
}

export default CategoriesActions
