import INITIAL_STATE from './'
import WorkServicesActions from './actions'

import arrayMove from 'array-move'

export function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case WorkServicesActions.getWorkServicesRequest().type:
      return state.merge({
        loading: true,
        error: null,
      })
    case WorkServicesActions.getWorkServicesResponse().type:
      return state.merge({
        data: action.payload.response.data || state.data,
        loading: false,
        error: action.payload.response.error,
      })

    case WorkServicesActions.changeOrderWorkServices().type:
      return state.merge({
        data: arrayMove(state.data.asMutable(), action.payload.oldIndex, action.payload.newIndex)
      })

    case WorkServicesActions.postWorkServiceRequest().type:
      return state.merge({
        loading: true,
        error: null,
      })
    case WorkServicesActions.postWorkServiceResponse().type:
      return state.merge({
        data: [...state.data, action.payload.response.data],
        loading: false,
        error: action.payload.response.error,
      })


    case WorkServicesActions.deleteWorkServiceRequest().type:
      return state.merge({
        loading: true,
        error: null,
      })
    case WorkServicesActions.deleteWorkServiceResponse().type:
      return state.merge({
        data: state.data.filter( ws => ws._id !== action.payload.response.data._id ),
        loading: false,
        error: action.payload.response.error,
      })


    case WorkServicesActions.putWorkServiceRequest().type:
      return state.merge({
        updatingWorkService: action.payload.workService._id,
        updatingWorkServiceError: null,
        updatedWorkService: false,
        loading: true
      })
    case WorkServicesActions.putWorkServiceResponse().type:
      const data = state.data
                        .asMutable()
                        .map( ws => ws._id === action.payload.response.data._id ? action.payload.response.data : ws )
      data.sort( (a, b) => a["order"] < b["order"] && a["status"] < b["status"] )
      return state.merge({
        data,
        loading: false,
        updatingWorkService: null,
        updatingWorkServiceError: action.payload.error,
        updatedWorkService: action.payload.response.data !== null,
      })
    case WorkServicesActions.sortWorkServicesRequest().type:
      return state.merge({
        sortingWorkServices: true,
        sortingWorkServicesError: null,
        sortedWorkServices: false,
      })
    case WorkServicesActions.sortWorkServicesResponse().type:
      return state.merge({
        sortingWorkServices: false,
        sortingWorkServicesError: action.payload.error,
        sortedWorkServices: action.payload.error === undefined,
      })
    default:
      return state
  }

}
