import React, { useEffect } from 'react'

import NavigationTopBar from '../../Components/NavigationTopBar'

import { useDispatch, useSelector } from 'react-redux'

import WorkerActions from '../../Redux/Worker/actions'

const Request = ({ history, match }) => {

  const dispatch = useDispatch()
  const worker = useSelector( state => state.worker.worker )
  const workerUpdated = useSelector( state => state.worker.workerUpdated )

  useEffect( () => {
    dispatch( WorkerActions.getWorker(match.params.id))
  }, [dispatch, match.params.id] )


  useEffect( () => {
    if (workerUpdated) {
      history.goBack()
      dispatch(WorkerActions.resetWorker())
    }
  }, [workerUpdated])

  return (
    <div>
      <NavigationTopBar selected={3} />
      <div className="screen-container">
        <div className="container" style={{ padding: 15 }}>
          <div className="columns">
            <div className="column">
              <section className="hero">
                <div className="hero-body">
                  <div className="container">
                    <div className="columns">
                      <div className="column is-four-fifths">
                        <h1 className="title">{ (worker?.user?.firstname || "") + " " + (worker?.user?.lastname || "") } </h1>
                        <div className="tags">
                        { worker?.work_services?.map( ws => (
                          <span key={ws._id} className="tag is-dark">
                            { ws.name }
                          </span>
                        ) ) }
                        </div>
                      </div>
                      <div className="column ">
                        <div className="buttons are-small">
                          { worker?.status === "completed" && (
                            <button
                              className="button is-outlined is-success"
                              onClick={ () => dispatch( WorkerActions.approveWorker(worker) ) }>
                              Aprobar
                            </button>
                          )}

                          <button
                            className="button is-outlined is-danger"
                            onClick={ () => dispatch( WorkerActions.rejectWorker(worker) ) }>
                            Rechazar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <h2 className="subtitle"> {" "} Datos basicos </h2>
            </div>
          </div>
          <div className="columns" style={{ padding: 15 }}>
            <div className="column">
              <div className="columns">
                <div className="column">
                  <span className="has-text-dark has-text-weight-bold">
                    Email:
                  </span>
                  { " " + (worker?.user?.email || "") }
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <span className="has-text-dark has-text-weight-bold">
                    { worker?.user?.dni_type }.
                  </span>
                  { " " + (worker?.user?.dni || "") }
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <span className="has-text-dark has-text-weight-bold">
                    Ciudad:
                  </span>
                  { " " + (worker?.user?.city || "") }
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <span className="has-text-dark has-text-weight-bold">
                    Contacto:
                  </span>
                  { " " + (worker?.user?.phone || "") }
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <h2 className="subtitle"> {" "} Otros datos </h2>
            </div>
          </div>
          <div className="columns" style={{ padding: 15 }}>
            <div className="column">
              <div className="columns">
                <div className="column">
                  <span className="has-text-dark has-text-weight-bold">
                    EPS:
                  </span>
                  { " " + (worker?.eps || "") }
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <span className="has-text-dark has-text-weight-bold">
                    ARL:
                  </span>
                  { " " + (worker?.arl || "") }
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <h2 className="subtitle"> {" "} Referencias </h2>
            </div>
          </div>
          <div className="columns" style={{ padding: 15 }}>
            <div className="column">
              <div className="columns">
                { worker?.references?.map( (reference, index) => (
                  <div key={index} className="column">
                    <div className="card">
                      <div className="card-content">
                        <p className="title">
                          { reference.fullname }
                        </p>
                        <p className="subtitle">
                          { reference.phone }
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Request
