import React from 'react'

import TextField from '../../../Components/Form/Inputs/textfield'
import DateField from '../../../Components/Form/Inputs/date'
import SelectField from '../../../Components/Form/Inputs/select'

import { useForm } from 'react-form'

import validate from 'validate.js'
import moment from 'moment'

validate.extend(validate.validators.datetime, {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse: function(value, options) {
    return +moment.utc(value);
  },
  // Input is a unix timestamp
  format: function(value, options) {
    var format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
    return moment.utc(value).format(format);
  }
});

const ARLOptions = [
  "AXA COLPATRIA S.A.",
  "COLMENA SEGUROS.",
  "COMPAÑÍA DE SEGUROS DE VIDA AURORA S.A.",
  "LA EQUIDAD SEGUROS DE VIDA",
  "LIBERTY SEGUROS DE VIDA S.A.",
  "MAPFRE SEGUROS",
  "POSITIVA",
  "SEGUROS BOLÍVAR S.A.",
  "SEGUROS DE VIDA ALFA S.A.",
  "SURATEP SA",
]

const DocumentTypes = [
  { id: 'CC', label: 'Cédula de Ciudadanía'},
  { id: 'CE', label: 'Cédula de Extranjería'},
  { id: 'PA', label: 'Pasaporte'},
  { id: 'RC', label: 'Registro Civil'},
  { id: 'TI', label: 'Tarjeta de Identidad'},
]


const constraints = {
  firstname: {
    presence: {allowEmpty: false},
  },
  lastname: {
    presence: {allowEmpty: false},
  },
  birthday: {
    presence: {allowEmpty: false},
    datetime: {
      dateOnly: true,
      latest: moment.utc().subtract(18, 'years'),
      message: "^Debes ser mayor de edad"
    }
  },
  phone: {
    presence: {allowEmpty: false},
  },
  dni: {
    presence: {allowEmpty: false},
  },
}

function BasicsForm({ defaultValues, epsOptions, onSubmit }) {
  const {
    Form,
    meta: { isSubmitting, canSubmit }
  } = useForm({
    onSubmit: onSubmit,
    debugForm: false,
    defaultValues,
  })

  return (
    <Form>
      <div className="columns">
        <div className="column">
          Nombres
        </div>
      </div>
      <div className="columns">
        <div className="column is-4">
          <div className="field">
            <div className="control">
              <TextField
                name="firstname"
                placeholder="Nombres"
                validate={ value => validate({firstname: value}, constraints).firstname?.[0] || null } />
            </div>
          </div>
        </div>
        <div className="column is-4">
          <div className="field">
            <div className="control">
              <TextField
                name="lastname"
                placeholder="Apellidos"
                validate={ value => validate({lastname: value}, constraints).lastname?.[0] || null }/>
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          Fecha de nacimiento
        </div>
      </div>
      <div className="columns">
        <div className="column is-4">
          <div className="field">
            <div className="control">
              <DateField
                name="birthday"
                placeholder="Fecha de nacimiento"
                validate={ value => validate({birthday: value}, constraints).birthday?.[0] || null }/>
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          Celular
        </div>
      </div>
      <div className="columns">
        <div className="column is-4">
          <div className="field">
            <TextField
              name="phone"
              placeholder="Teléfono"
              validate={ value => validate({phone: value}, constraints).phone?.[0] || null } />
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          Documento de identidad
        </div>
      </div>
      <div className="columns">
        <div className="column is-2">
          <SelectField
            field="dni_type"
            options={DocumentTypes}
            validate={value => (!value ? "This is required!" : false)} />
        </div>
        <div className="column is-2">
          <div className="field">
            <div className="control">
              <TextField
                name="dni"
                placeholder="Numero de documento"
                validate={ value => validate({dni: value}, constraints).dni?.[0] || null } />
            </div>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          Ciudad de trabajo
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <SelectField
            field="city"
            options={["Bogotá D.C."]}
            validate={value => (!value ? "This is required!" : false)} />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          EPS
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <SelectField
            field="eps"
            options={ epsOptions }
            validate={value => (!value ? "This is required!" : false)} />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          ARL
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <SelectField
            field="arl"
            options={ARLOptions}
            validate={value => (!value ? "This is required!" : false)} />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <button
            className="button is-link is-rounded"
            type="submit"
            disabled={!canSubmit}>
            Siguiente
          </button>
        </div>
      </div>
    </Form>
  )
}

export default BasicsForm
