import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import registerServiceWorker from './registerServiceWorker'

import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import { composeWithDevTools } from 'redux-devtools-extension'

import { Provider } from 'react-redux'
import store from './Redux'
import Language from './Language'

// create middlewares
const composeEnhancers = composeWithDevTools({})

const middlewares = [thunk]

const createdStore = createStore(store,
  composeEnhancers (
    applyMiddleware(...middlewares)
  )
)

Language()

ReactDOM.render(
  <Provider store={ createdStore }>
    <App />
  </Provider>, document.getElementById('root'))
registerServiceWorker()
