import React from 'react'
import { useField, splitFormProps } from 'react-form'
import clsx from 'clsx'

function SelectField(props) {
  const [field, fieldOptions, { options, ...rest }] = splitFormProps(props);

  const {
    value = '',
    setValue,
    meta: { error, isTouched }
  } = useField(field, fieldOptions);

  const handleSelectChange = e => {
    setValue(e.target.value);
  };

  return (
    <div className={ clsx("select", isTouched && error ? "is-danger" : null ) }>
      <select {...rest} value={value} onChange={handleSelectChange}>
        <option disabled value="" />
        {options.map(option => {
          if (option.label && option.id) {
            return (
              <option key={option.id} value={option.id}>
                {option.label}
              </option>
            )
          }else {
            return (
              <option key={option} value={option}>
                {option}
              </option>
            )
          }
        })}
      </select>{" "}
    </div>
  );
}

export default SelectField
