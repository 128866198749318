import React from 'react'
import { useField } from 'react-form'
import validate from 'validate.js'
import clsx from 'clsx'

const DateField = ({ name, placeholder, validate }) => {
  const {
    meta: { error, isTouched },
    getInputProps
  } = useField('birthday', {
    validate: value => {
      return validate(value)
    }
  })

  return (
    <>
      <input
        {...getInputProps()}
        className={ clsx("input", error ? "is-danger" : null ) }
        type="date"
        placeholder={placeholder} />
      {isTouched && error ? (
        <em className="has-text-danger">{error}</em>
      ) : null}
    </>
  )
}

export default DateField
