import React from 'react'

import { Link } from 'react-router-dom'

import NavigationTopBar from '../../Components/NavigationTopBar'
import Loader from '../../Components/Loader'

import AccountActions from '../../Redux/Account/actions'
import { useDispatch, useSelector } from 'react-redux'

import i18n from 'i18next'

const AccountScreen = ({ location }) => {

  const [ editing, setEditing ] = React.useState(false)
  const [ firstname, setFirstname ] = React.useState('')
  const [ lastname, setLastname ] = React.useState('')
  const [ showUpdatedMessage, setShowUpdatedMessage] = React.useState(false)

  const {
    updatingProfile,
    updatedProfile
  } = useSelector( state => state.account )

  const profile = useSelector( state => state.auth.user )

  const dispatch = useDispatch()

  const params = new URLSearchParams(location.search)

  React.useEffect( () => {
    setEditing( (/true/i).test(params.get('edit')) || params.get('edit') === '' )
  }, [params])

  React.useEffect( () => {
    if (updatedProfile) {
      setShowUpdatedMessage(true)
    }
  }, [updatedProfile])

  React.useEffect( () => {
    setFirstname( profile.firstname )
    setLastname( profile.lastname )
  }, [profile])

  function save() {
    dispatch( AccountActions.updateProfile({ firstname, lastname }) )
  }

  return (
    <div>
      <div>
        <NavigationTopBar />
        <br/>
        <div className="columns is-centered">
          <div className="column is-4">
            <div className="box">
              <div className="field">
                <label className="label">{ i18n.t("firstname") }</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder={ i18n.t("firstname") }
                    disabled={ !editing }
                    onChange={ event => setFirstname(event.target.value) }
                    value={ firstname } />
                </div>
              </div>
              <div className="field">
                <label className="label">{ i18n.t("lastname") }</label>
                <div className="control">
                  <input
                    className="input"
                    type="text"
                    placeholder={ i18n.t("lastname") }
                    disabled={ !editing }
                    onChange={ event => setLastname(event.target.value) }
                    value={ lastname } />
                </div>
              </div>
              { editing && (
                <div className="field is-grouped">
                  <div className="control columns is-vcentered">
                    <div className="column">
                      <button
                        className="button is-link"
                        onClick={ () => save() }>
                        { i18n.t("save") }
                      </button>
                    </div>
                    { updatingProfile && (
                      <div className="column is-paddingless">
                        <Loader />
                      </div>
                    )}
                  </div>
                </div>
              )}

              { showUpdatedMessage && (
                <div className="message is-primary">
                  <div className="message-header">
                    <button
                      className="delete"
                      aria-label="delete"
                      onClick={ () => setShowUpdatedMessage(false) }>
                    </button>
                  </div>
                  <div className="message-body">
                    { i18n.t("updatedProfile") }
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column is-4">
          <Link
            to='/change-password'
            className='link'>{ i18n.t('changePassword') }</Link>
          </div>
        </div>

      </div>
    </div>
  )
}

export default AccountScreen
