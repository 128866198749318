import Immutable from 'seamless-immutable'

const INITIAL_STATE = Immutable({
  eps: [],
  epsLoading: false,
  epsError: null,

  workers: [],
  workersPagination: { page: 0, limit: 10, total: 0, totalPages: 0 },
  workersLoading: false,
  workersLoadingError: null,

  worker: null,
  workerLoading: false,
  workerLoadingError: null,


  workerUpdated: false,
})

export default INITIAL_STATE
