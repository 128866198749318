import { create } from 'apisauce'

const api = create({
  // baseURL: 'https://felipecarreramo.usw1.kubesail.io',
  // baseURL: 'http://104.197.199.120:8080',
  baseURL: process.env.REACT_APP_API_BASE_URL,
  // baseURL: 'https://moya-api.imperia.solutions',
})

export default api
