import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import HomeScreen from '../Containers/HomeScreen'
import LoginScreen from '../Containers/Account/LoginScreen'
import SignupScreen from '../Containers/Account/SignUpScreen'

import WorkerSignup from '../Containers/Worker/Signup'
import WorkerThanks from '../Containers/Worker/Thanks'
import WorkerRequests from '../Containers/Worker/Requests'
import WorkerRequest from '../Containers/Worker/Request'

import AccountScreen from '../Containers/Account/AccountScreen'
import ChangePasswordScreen from '../Containers/Account/ChangePasswordScreen'
import ForgotPasswordScreen from '../Containers/Account/ForgotPasswordScreen'

import Categories from '../Containers/Categories'
import WorkServicesScreen from '../Containers/WorkServices'

import LoadingScreen from '../Containers/LoadingScreen'

export function routes(isAuthenticated, isLoading) {

  if (isLoading) {
    console.log('isLoading routes')
    return (
        <Router>
          <Switch>
            <Route
              path="*"
              exact
              component={LoadingScreen} />
          </Switch>
        </Router>
      )
  }

  if (isAuthenticated) {

    console.log('isAuthenticated routes')

    return (
        <Router>
          <Switch>
            <Route
              path="/home"
              exact
              component={HomeScreen} />

            <Route
              path="/account"
              exact
              component={AccountScreen} />

            <Route
              path="/change-password"
              exact
              component={ChangePasswordScreen} />

            <Route
              path="/categories"
              exact
              component={Categories} />

            <Route
              path="/work-services"
              exact
              component={WorkServicesScreen} />

            <Route
              path="/worker/signup"
              exact
              component={WorkerSignup} />

            <Route
              path="/worker/thanks"
              exact
              component={WorkerThanks} />

            <Route
              path="/worker/requests"
              exact
              component={WorkerRequests} />

            <Route
              path="/worker/requests/:id"
              exact
              component={WorkerRequest} />

            <Route render={ () => <Redirect to="/home" /> } />

          </Switch>
        </Router>
      )
  }

  console.log('Not Authenticated routes')

  return (
      <Router>
        <Switch>
          <Route
            path="/"
            exact
            component={LoginScreen} />

          <Route
            path="/register"
            exact
            component={SignupScreen} />

          <Route
            path="/worker/signup"
            exact
            component={WorkerSignup} />

          <Route
            path="/worker/thanks"
            exact
            component={WorkerThanks} />

          <Route
            path="/forgot-password"
            exact
            component={ForgotPasswordScreen} />

          <Route render={ () => <Redirect to="/" /> } />

        </Switch>
      </Router>
    )
}
