import React from 'react'

import styles from './styles.module.sass'

const LoadingScreen = () => {
  return (
    <div className={ styles.container }>
      Cargando...
    </div>
  )
}

export default LoadingScreen
