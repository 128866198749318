import { createActions } from 'redux-actions'

import {
  me,
  setAuthorizationToken,
  restoreAuthorizationToken,
  removeCurrentAuthorizationToken,
} from '../../Services/Account'

const AuthActions = createActions({
  SET_USER: user => ({ user }),
  VERIFY_AUTH: () => {},
  RESTORED_AUTH: () => {},
  NO_AUTH: () => {},
  ACCESS_TOKEN_SAVED: token => ({ token }),
})

AuthActions.restoreAuth = () => {
  return async dispatch => {
    dispatch( AuthActions.verifyAuth() )
    restoreAuthorizationToken()
    const meResponse = await me()
    console.log('meResponse', meResponse)
    if (!meResponse.ok) return dispatch( AuthActions.noAuth() )
    dispatch(AuthActions.setUser( meResponse.data.data ))
    dispatch( AuthActions.restoredAuth() )
  }
}

AuthActions.removeAuth = () => {
  return dispatch => {
    setAuthorizationToken(null)
    removeCurrentAuthorizationToken()
    dispatch( AuthActions.noAuth() )
  }
}

AuthActions.setAccessToken = token => {
  return dispatch => {
    setAuthorizationToken(token)
    dispatch( AuthActions.accessTokenSaved(token) )
  }
}

export default AuthActions
