import React from 'react'

import MultiSelectField from '../../../Components/Form/Inputs/multiselect'

import { useForm } from 'react-form'

function ServicesForm({ defaultValues, options, onSubmit, onCancel }) {
  const {
    Form,
    meta: { isSubmitting, canSubmit }
  } = useForm({
    onSubmit: onSubmit,
    debugForm: false,
    defaultValues,
  })

  return (
    <Form>
      <div className="columns">
        <div className="column is-2">
          <MultiSelectField
            field="work_services"
            options={options}
            validate={value => (!value ? "This is required!" : false)} />
        </div>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>

      <div className="columns">
        <div className="column">
          <div className="buttons">
            <button
              className="button is-success"
              type="submit"
              disabled={!canSubmit}>
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default ServicesForm
