import Immutable from 'seamless-immutable'

const INITIAL_STATE = Immutable({
  data: [],
  loading: false,
  error: null,

  sortingWorkServices: false,
  sortingWorkServicesError: null,
  sortedWorkServices: false,

  updatingWorkService: null,
  updatingWorkServiceError: null,
  updatedWorkService: false,
})

export default INITIAL_STATE
