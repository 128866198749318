export default currentPagination => {
  let currentPage = currentPagination.page
  const totalPages = currentPagination.totalPages
  const pageSize = currentPagination.limit
  const totalItems = currentPagination.total

  const maxPages = 4
  if (currentPage < 1) {
     currentPage = 1;
 } else if (currentPage > totalPages) {
     currentPage = totalPages;
 }else {
   currentPage += 1
 }

 let startPage: number, endPage: number;
 if (totalPages <= maxPages) {
     // total pages less than max so show all pages
     startPage = 1;
     endPage = totalPages;
 } else {
     // total pages more than max so calculate start and end pages
     let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
     let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
     if (currentPage <= maxPagesBeforeCurrentPage) {
         // current page near the start
         startPage = 1;
         endPage = maxPages;
     } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
         // current page near the end
         startPage = totalPages - maxPages + 1;
         endPage = totalPages;
     } else {
         // current page somewhere in the middle
         startPage = currentPage - maxPagesBeforeCurrentPage;
         endPage = currentPage + maxPagesAfterCurrentPage;
     }
 }

 // calculate start and end item indexes
 let startIndex = (currentPage - 1) * pageSize;
 let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

 // create an array of pages to ng-repeat in the pager control
 let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

 return { totalPages, currentPage, totalItems, startIndex, endIndex, pages, maxPages }
}
